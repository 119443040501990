import {convertBase} from './lib/utils';
import {PAD} from './constants';

export function generateController(config) {
  return {
  	rands: [],
    randomize: function(){
      this.rands = [];
      for (var i in config) {
        this.rands.push({name: config[i].name, value: Math.floor(Math.random()*config[i].max)});
      }
      this.shortcode = this.generateShortcode();
    },
    change: function(configName) {
    	if(!configName) {
    		return false;
    	}
    	for (var i = 0; i < this.rands.length; i++) {
    		if(configName && this.rands[i].name === configName) {
    			this.rands[i].value = Math.floor(Math.random()*config[i].max);
    		}
      }
      this.shortcode = this.generateShortcode();
    },
    generateShortcode: function(){
      var concat = [];
      for (var i = 0; i < config.length; i++) {
        concat.push((PAD+this.rands[i].value).slice(-1*(""+config[i].max).length));
      }
      return convertBase(concat.join(""), 10, 62);
    },
    hydrate: function(shortcode){
      const concatValue = (PAD + convertBase(shortcode, 62, 10)).slice(-13);
      var index = 0;

      this.shortcode = shortcode;

      for (var i = 0; i < config.length; i++) {
      	const value = concatValue.slice(index, index+(""+config[i].max).length);
      	if(parseInt(value, 10) <= config[i].max) {
      		this.rands[i] = {name: config[i].name, value:parseInt(value, 10)};
        	index += (""+config[i].max).length;
      	} else {
      		this.randomize();
      		break;
      	}
      }
    }
  }
}
