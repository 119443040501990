import {generateController} from './controller';
import {ONE_SYLLABLE, TWO_SYLLABLE, CONFIG, BASE_URL, CHECKOUT_CONFIG, CHECKOUT_SUBJECT, CHECKOUT_OBJECT} from './constants';
import xhr from "xhr";

const controller = generateController(CONFIG);
const donateController = generateController(CHECKOUT_CONFIG);

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

window.make = function (){
  controller.randomize();
  render();
}

window.render = function(){
  function get_variant(value) {
    if(value.word){
      if(controller.rands[2].value){
        return value.word;
      }else{
        return value.variant;
      }
    }
    return value;
  }

  var values = {
    word1: controller.rands[3].value ? get_variant(ONE_SYLLABLE[controller.rands[0].value]) : get_variant(TWO_SYLLABLE[controller.rands[1].value]),
    word2: controller.rands[3].value ? get_variant(TWO_SYLLABLE[controller.rands[1].value]) : get_variant(ONE_SYLLABLE[controller.rands[0].value])
  }

  name = `${values.word1.capitalize()} & ${values.word2.capitalize()}`;
  window.location.hash = controller.shortcode;
  document.getElementById("initial1").innerHTML = values.word1[0];
  document.getElementById("initial2").innerHTML = values.word2[0];
  document.getElementById("compname").innerHTML = name;
  document.getElementById("logo").className = "b"+controller.rands[5].value;
  document.getElementById("icon").className = "b"+controller.rands[6].value;
  document.getElementById("compname").className = "font"+controller.rands[4].value;
  document.getElementById("register").href = BASE_URL + encodeURIComponent(name.replace("&", "and"));
  document.getElementById("twitter").href = `https://twitter.com/share?text=${encodeURIComponent(name)}&url=${window.location.href}`
}

window.checkoutRender = function(){
  var value = [CHECKOUT_SUBJECT[donateController.rands[0].value], CHECKOUT_OBJECT[donateController.rands[1].value]].join(" ");
  document.getElementById('donateReason').innerHTML = value;
}

window.load = function(){
  if(window.location.hash) {
    controller.hydrate(window.location.hash.substring(1));
  } else {
    controller.randomize();
  }
  render();

  var name = document.getElementById("compname"),
    icon = document.getElementById("icon"),
    logo = document.getElementById("logo"),
    modal = document.getElementById("modal"),
    btn = document.getElementById("donate"),
    span = document.getElementsByClassName("close")[0],
    reload = document.getElementById('reloadDonation'),
    amountButtons = document.getElementsByClassName("amount"),
    custom = document.getElementById("custom"),
    submit = document.getElementById("stripe"),
    STRIPE_KEY = submit.getAttribute("data-stripekey");

  var handler = StripeCheckout.configure({
    key: STRIPE_KEY,
    image: 'http://www.hipsterbusiness.name/preview.png',
    locale: 'auto',
    token: function(token) {

      xhr({
        method: "post",
        body: JSON.stringify({
          stripeToken: token.id, 
          stripeEmail: token.email, 
          amount: donateController.amount
        }),
        uri: "/charge",
        headers: {
            "Content-Type": "application/json"
        }
      }, function (err, resp, body) {});
      modal.style.display = "none";
      donateController.amount = 0;
      disableSubmit();
    }
  });

  name.addEventListener('touchend',function(e) {
    e.preventDefault();
    change("font_variant")
  });
  name.addEventListener('click',function(e) {
    change("font_variant")
  });

  icon.addEventListener('touchend',function(e) {
    e.preventDefault();
    e.stopPropagation();
    change('icon_variant');
  });
  icon.addEventListener('click',function(e) {
    e.stopPropagation();
    change('icon_variant');
  });

  logo.addEventListener('touchend',function(e) {
    e.preventDefault();
    change('cross_variant');
  });
  logo.addEventListener('click',function(e) {
    change('cross_variant');
  });

  function donationInitializer () {
    ga('send', 'event', 'donate_init', 'click');
    donateController.randomize();
    checkoutRender();
    modal.style.display = "block";
  };

  btn.addEventListener('touchend',function(e) {
    e.preventDefault();
    donationInitializer();
  });
  btn.addEventListener('click',function(e) {
    donationInitializer();
  });

  reload.onclick = function() {
    donateController.randomize();
    checkoutRender();
  };

  span.onclick = function() {
    modal.style.display = "none";
  };

  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }

  function deselectButtons() {
    disableSubmit();
    for(var i = 0; i < amountButtons.length; i++) {
      amountButtons[i].className = "button amount";
    }
  }

  function enableSubmit(value) {
    donateController.amount = value * 100;
    submit.innerHTML = "Donate $" + (donateController.amount/100).toFixed(2);
    submit.removeAttribute("disabled");
  }

  function disableSubmit() {
    submit.innerHTML = "Donate";
    submit.setAttribute("disabled", true);
  }

  function createSelect(button) {
    return function() { 
      deselectButtons();
      button.className = "button amount selected";
      enableSubmit(parseInt(button.value, 10));
    }
  }

  for(var i = 0; i < amountButtons.length; i++) {
    amountButtons[i].onclick = createSelect(amountButtons[i]);
  }

  custom.onclick = function() {
    deselectButtons();
    initCustomValue();
  }

  function initCustomValue() {
    var val = Number(parseFloat(custom.value, 10).toFixed(2));
    disableSubmit();
    if(val > 0){
      enableSubmit(val);
    }
  }

  custom.addEventListener("input", function(){
    initCustomValue();
  });

  submit.onclick = function(event) {
    event.preventDefault();
    ga('send', 'event', 'donate_stripe', 'click');
    handler.open({
      name: 'Hipster Business',
      description: 'Donation',
      zipCode: true,
      amount: donateController.amount
    });
  }

  window.addEventListener('popstate', function() {
    handler.close();
  });
}

window.change = function(configName) {
  controller.change(configName);
  render();
}
